<template>
  <v-card @click="goToWebsite(site.website_id)" hover style="border-radius: 0.5rem;" outlined>
    <ActionDialog ref="sync_dialog" title="Sync Website" color="success">
      <template v-slot="{ options }">
        <v-select
          v-model="options.data.settings"
          :items="sync_site_options"
          :rules="[v => v.length >= 1 || 'You must select at least one type']"
          label="Sync Settings"
          outlined
          multiple
          hide-details=auto
          class="rounded-lg"
        ></v-select>
      </template>
    </ActionDialog>
    <v-img
      :src="site.image_url"
      class="white--text align-end"
      gradient="to bottom, rgba(0,0,0,.01), rgba(0,0,0,.1)"
      max-height="100px"
      contain
    >
      <template v-slot:placeholder>
        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
      </template>
    </v-img>
    <v-card-title class="justify-center">
      {{ site.name }}

      <div style="position: absolute; right: 0; top: 0;">
        <v-menu bottom right absoloute>
          <!-- Button with vertical dots -->
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon >mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <!-- Edit Button -->
            <v-list-item v-if="access.update_access">
              <v-btn block text @click="goToWebsite(site.website_id)">
                Edit Website
              </v-btn>
            </v-list-item>
            <!-- Audit Log Button -->
            <v-list-item>
              <v-btn block text @click.stop="showAuditLog = true; getLogs()">
                Audit Log
              </v-btn>
              <AuditLogDialog
                :show.sync="showAuditLog"
                :logs="auditLogs"
              ></AuditLogDialog>
            </v-list-item>
            <!-- Sync Button -->
            <v-list-item style="justify-content: center;" v-if="access.update_access">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    block
                    v-bind="attrs"
                    v-on="on"
                    @click="onSyncWebsite">
                    Sync Website
                  </v-btn>
                </template>
                <span>By pressing this you will map your products, categories and tags to your ItemFlo account<br/>This will not push updates to the products on this site.</span>
              </v-tooltip>
            </v-list-item>
            <!-- Delete Button -->
            <v-list-item v-if="access.delete_access">
              <v-btn
                text
                block
                color="error"
                @click="$emit('delete', { website_id: site.website_id, website_name: site.name })"
              >
              Delete Website
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-card-title>

    <div v-if="additional_info">
      <div v-for="(info, i) in additional_info" :key="i">
        <div v-if="info.show">
          <v-divider></v-divider>
          <v-card-text class="text-center" style="padding: 4px 10px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{info.title}}: {{info.info}}</v-card-text>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { auditLogsLogic } from '@/mixins/auditLogsMixin.js'
import AuditLogDialog from '@/components/auditLogs/AuditLogDialog.vue'
import ActionDialog from "@/components/ActionDialog.vue"
export default {
  mixins: [auditLogsLogic],
  props: {
    site: Object,
    additional_info: Array,
    access: Object,
    syncWebsite: Function,
    goToWebsite: Function,
  },
  data() {
    return {
      showAuditLogKey: 0,
      showAuditLog: false,
      auditLogs: [],
      sync_data: {
        settings: [],
      },
    }
  },
  components: {
    AuditLogDialog,
    ActionDialog,
  },
  computed: {
    sync_site_options() {
      switch (this.site.type) { 
        case 'Shopify':
          return ['Products', 'Tags']
        case 'B2BWave':
          return ['Products', 'Brands', 'Categories', 'Price Lists']
        default:
          return [];
      }
    },
  },
  methods: {
    async getLogs() {
      this.auditLogs = await this.getAuditLogs(this.site.website_id, this.$route.path.split('/')[1]);
    },
    getSyncDefaults() {
      switch (this.site.type) { 
        case 'Shopify':
          return ['Products', 'Tags'];
        case 'B2BWave':
          return ['Products', 'Brands', 'Categories'];
        default:
          return [];
      }
    },
    onSyncWebsite() {
      this.sync_data.settings = this.getSyncDefaults();
      this.$refs.sync_dialog.showDialog(this.sync_data)
        .then(data => {
          this.syncWebsite({ website_id: this.site.website_id, website_name: this.site.name, settings: data.settings });
        })
        .catch(() => {
          this.sync_data.settings = this.getSyncDefaults();
        });
    },
  }
};
</script>