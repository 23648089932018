<template>
  <v-row v-if="websites.length === 0" dense>
    <v-col cols="12" md="4" v-for="index in 3" :key="index" style="padding: 8px">
      <v-skeleton-loader
        type="card, list-item-three-line"
        elevation=2
      ></v-skeleton-loader>
    </v-col>
  </v-row>
  <v-row v-else dense>
    <v-col cols="12" sm="4" v-for="(site, index) in websites" :key="site.website_id">
      <SiteCard
      :site="site"
      :additional_info="[
        {
          'title': 'API User',
          'info': site.api_user,
          'show': false
        },
        {
          'title': 'API Key',
          'info': site.api_key,
          'show': false
        },
        {
          'title': 'URL',
          'info': site.url,
          'show': true
        },
      ]"
      :index="index"
      :access="access"
      :syncWebsite="syncWebsite"
      :goToWebsite="goToWebsite"
      v-on="$listeners"
      ></SiteCard>
    </v-col>
  </v-row>
</template>

<script>
import SiteCard from "@/components/sites/SiteCard.vue"
export default {
  props: {
    websites: Array,
    access: Object,
    syncWebsite: Function,
    goToWebsite: Function,
  },
  components: {
    SiteCard,
  }
}
</script>