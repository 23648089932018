<template>
  <v-container fluid>
    <Header 
      title="Your Sites"
      :subtitle="`Website Usage: ${this.$store.getters.getOrganizationState.website_count}/${this.$store.getters.getOrganizationSettings.website_limit}`"
      :divider="false"
      :options="header_options"
    ></Header>

    <v-divider class="mt-2 mb-5"/>
    
    <CreateSite
      ref="create_dialog"
      :access="access"
    ></CreateSite>

    <ActionDialog ref="delete_dialog" title="Deleting Website" color="error">
      <template v-slot>
        <span class="error--text" v-html="'Are you sure you want to delete this website.<br>Warning! This will remove all links related to this website.'"></span>
      </template>
    </ActionDialog>

    <ActionDialog ref="sync_dialog" title="Sync Websites" color="success">
      <template v-slot="{ options }">
        <v-select
          v-model="options.data.settings"
          :items="['Products', 'Brands', 'Categories', 'Price Lists']"
          :rules="[v => v.length >= 1 || 'You must select at least one type']"
          label="Sync Settings"
          outlined
          multiple
          hide-details=auto
          class="rounded-lg"
        ></v-select>
      </template>
    </ActionDialog>

    <SiteCards
      v-if="websites.length !== 0"
      :websites="websites"
      :access="access"
      :syncWebsite="syncWebsite"
      :goToWebsite="goToWebsite"
      @delete="site => $refs.delete_dialog.showDialog({ site: site }).then(data => deleteWebsite(data.site)).catch(() => {})"
    ></SiteCards>
    <CreateSite
      v-else
      ref="create_website_dialog"
      :title="'Create your first website!'"
      :flat="true"
      :access="access"
      :showOptions="false"
    ></CreateSite>
  </v-container>
</template>

<script>
import { websitesCore, websitesLogic } from "@/mixins/websitesMixin.js";
import Header from "@/components/Header.vue"
import SiteCards from "@/components/sites/SiteCards.vue"
import CreateSite from "@/components/sites/CreateSite.vue"
import ActionDialog from "@/components/ActionDialog.vue"
export default {
  mixins: [websitesCore, websitesLogic],
  components: {
    Header,
    CreateSite,
    SiteCards,
    ActionDialog,
  },
  data() {
    return {
      access: this.$store.getters.getAccessLevel['websites'],
      sync_data: {
        settings: ['Products', 'Brands', 'Categories'],
      },
    };
  },
  computed: {
    siteLimitMet() {
      return this.$store.getters.getOrganizationSettings.website_limit <= this.$store.getters.getOrganizationState.website_count;
    },
    header_options() {
      let options = [{
          'title': 'Sync All Sites',
          'icon': 'mdi-autorenew',
          'disabled': !this.access.update_access,
          'action': this.onSyncWebsites
      }]
      if (this.websites.length !== 0) {
        options.push({
          'title': 'Add Site',
          'title_disabled': 'You have reached your website limit.',
          'icon': 'mdi-plus',
          'disabled': !this.access.create_access || this.siteLimitMet,
          'action': () => {this.$refs.create_dialog.show();}
        })
      }
      return options; 
    }
  },
  methods: {
    async onSyncWebsites() {
      this.$refs.sync_dialog.showDialog(this.sync_data)
        .then(data => {
          for (var i in this.websites) {
            this.syncWebsite({ website_id: this.websites[i].website_id, website_name: this.websites[i].name, settings: data.settings });
          }
        })
        .catch(() => {
          this.sync_data.settings = ['Products', 'Brands', 'Categories'];
        });
    }
  }
};
</script>